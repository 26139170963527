import { ChevronRight } from '@abcam-web/lego/ui/icons'
import type {
  EmailButtonAssetModel,
  LinkButtonAssetModel,
} from '@abcam-web/shared/ui/content'
import { LinkButton, AEMHTMLContent } from '@abcam-web/shared/ui/content'
import { Button } from '@lego/ui/button'
import { standardizeUrl } from '@abcam-web/shared/utilities/seo'
import { BUTTON_VARIANT } from '@lego/ui/button'
import cn from 'classnames'

export type ContactCardProps = {
  title?: string | null
  content?: string | null
  button?: LinkButtonAssetModel | EmailButtonAssetModel
  buttonVariant?: BUTTON_VARIANT | keyof typeof BUTTON_VARIANT
  classNames?: string
}

const ContactCard = ({
  title,
  content,
  classNames,
  button,
  buttonVariant = BUTTON_VARIANT.primary,
}: ContactCardProps) => {
  return (
    <div
      className={cn(
        'flex items-center justify-between space-y-[14px] p-layout-3 tyd:flex-col tyd:items-start gap-y-[22px] bg-grey-5 rounded-8px',
        classNames
      )}
    >
      {(title || content) && (
        <div className="tyu:pr-layout-3 space-y-2">
          {title && (
            <h3
              id={standardizeUrl(title)}
              className="font-semibold text-heading-medium text-grey-dark"
            >
              {title}
            </h3>
          )}
          {content && (
            <div className="mt-2 font-semibold [&_p]:mb-0 [&_a]:text-grey-dark text-grey-dark text-heading-x-small">
              <AEMHTMLContent render={content} />
            </div>
          )}
        </div>
      )}

      {button && button.__typename === 'LinkButtonAssetModel' && (
        <LinkButton
          linkProps={{ className: '!mt-0' }}
          linkButton={button}
          buttonProps={{
            variant: buttonVariant,
            size: 'small',
            iconRight: <ChevronRight />,
          }}
        />
      )}
      {button && button.__typename === 'EmailButtonAssetModel' && (
        <Button
          as="a"
          href={`mailto:${button.buttonEMail}`}
          iconRight={<ChevronRight />}
          size="small"
          variant={buttonVariant}
        >
          {button.buttonLabel}
        </Button>
      )}
    </div>
  )
}

export { ContactCard }
