import React, { FC } from 'react'
import cn from 'classnames'
import type { StepsProps, Step } from './steps.type'
import styles from './steps.module.css'

export const Steps: FC<StepsProps> = ({ steps, classOverrides }) => {
  return (
    <div className={cn(styles.steps, classOverrides?.wrapper)}>
      {steps.map(({ stepNumber, content }: Step, index: number) => (
        <div key={index} className={cn(styles.step, classOverrides?.step)}>
          <div className={cn(styles.number, classOverrides?.number)}>
            {stepNumber || index + 1}
          </div>
          <div className={cn(styles.content, classOverrides?.content)}>
            {content}
          </div>
        </div>
      ))}
    </div>
  )
}
