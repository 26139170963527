import type { MultiFormatString } from '@abcam-web/shared/ui/content'
import { AEMHTMLContent } from '@abcam-web/shared/ui/content'
import { useIntl } from 'react-intl'

export function RelatedLinks({
  relatedLinks,
}: {
  relatedLinks: Pick<MultiFormatString, 'html'>[]
}) {
  const { formatMessage } = useIntl()
  return (
    <>
      <h2
        className="px-2 py-3 text-grey-20 text-body-small"
        id="related-links-title"
      >
        {formatMessage({ id: 'common.relatedLinks' }).toLocaleUpperCase()}
      </h2>
      <ul>
        {relatedLinks.map((relatedLink, index) => {
          return (
            <li key={index} className="px-2 my-2 break-words">
              <AEMHTMLContent render={relatedLink.html as string} />
            </li>
          )
        })}
      </ul>
    </>
  )
}
