import type { FC } from 'react'
import cn from 'classnames'
import { useEnvironment } from '@abcam-web/shared/utilities/environment'
import { Button } from '@lego/ui/button'
import type { DownloadBoxProps } from './download-box.type'
import { useIntl } from 'react-intl'

export const DownloadBox: FC<DownloadBoxProps> = ({
  downloads,
  classOverrides,
}) => {
  const environment = useEnvironment()
  const { formatMessage } = useIntl()

  if (!downloads || downloads.length === 0) return null

  return (
    <div
      className={cn(
        'bg-grey-5 p-6 rounded-8px max-w-[544px]',
        classOverrides?.wrapper
      )}
    >
      {downloads.map((download, index) => {
        // Add a divider if there are multiple downloads
        // Don't add a divider if it's the last download in the list
        const multipleDownloads = downloads.length > 1
        const addDivider = multipleDownloads && index + 1 !== downloads.length
        const { title, type, requiresAuthentication, downloadUrl, source } =
          download

        // If the download is an external link, pass that link.
        // Else create the link to download from AEM.
        const sourceUrl =
          source === 'url'
            ? downloadUrl
            : `${environment.AEM_DAM_URL}${downloadUrl}`

        return (
          <div key={`${title}-${index}`}>
            <div
              className={cn(
                'mb-4 text-ui-large font-header font-semibold text-black-0',
                classOverrides?.title
              )}
            >
              {title}
            </div>
            <Button
              className={cn(classOverrides?.button)}
              as="a"
              href={sourceUrl}
              variant="secondary"
              size="medium"
            >
              {formatMessage({ id: 'common.download' })}
            </Button>
            <div
              className={cn(
                'mt-4 text-ui-small font-body text-black-0',
                classOverrides?.meta
              )}
            >
              <p>{type}</p>
            </div>
            {requiresAuthentication && (
              <>
                <div className="h-px my-4 bg-[#273f3f33]"></div>
                <p className="font-semibold text-ui-small text-black-0">
                  { formatMessage({id: "downloadBox.registerAndDownload"}) }
                </p>
              </>
            )}

            {addDivider && <div className="h-px my-4 bg-[#273f3f33]"></div>}
          </div>
        )
      })}
    </div>
  )
}
