import type { CallToActionCardProps } from './call-to-action-card.type'
import {
  AEMImage,
  AEMRenditions,
  LinkButton,
} from '@abcam-web/shared/ui/content'

const CallToActionCard = ({
  imageSrc,
  imageCaption,
  title,
  text,
  buttonLink,
}: CallToActionCardProps) => {
  return (
    <div className="flex flex-col max-h-full basis-1/3 justify-stretch">
      {imageSrc && (
        <div>
          <AEMImage
            className="object-cover"
            forceRendition={AEMRenditions.sm}
            alt={imageCaption ?? title}
            src={imageSrc}
          />
        </div>
      )}
      <div className="flex flex-col h-full px-8 py-10 bg-[#f2f2f2] justify-stretch">
        <span className="text-heading-medium font-body-semibold">{title}</span>
        <p className="h-full my-4 text-body-medium font-body">{text}</p>
        {/** Style attribute is there for overriding some styling coming from Button component, check it more thoroughly later */}
        {buttonLink && (
          <LinkButton
            linkButton={buttonLink}
            buttonProps={{
              style: { alignSelf: 'baseline' },
              size: 'medium',
              variant: 'primary',
            }}
          />
        )}
      </div>
    </div>
  )
}

export { CallToActionCard }
