import type {
  ImageAssetModel,
  LinkButtonAssetModel,
  RichContentAssetModel,
  RichTextAssetModel,
} from '@abcam-web/shared/ui/content'
import {
  richContentAssetModelPathModelIsImageAssetModel,
  richContentAssetModelPathModelIsLinkButtonAssetModel,
  richContentAssetModelPathModelIsRichTextAssetModel,
} from '@abcam-web/shared/ui/content'
import type { PropsWithChildren, ReactNode } from 'react'
import cn from 'classnames'
import { standardizeUrl } from '@abcam-web/shared/utilities/seo'

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export const RichContentAsset = ({
  model,
  headingTag,
  imageAssetModel,
  richTextAssetModel,
  linkButtonAssetModel,
}: {
  model: RichContentAssetModel
  headingTag: HeadingTag
  imageAssetModel: (image: ImageAssetModel) => ReactNode
  richTextAssetModel: (richText: RichTextAssetModel) => ReactNode
  linkButtonAssetModel: (linkButton: LinkButtonAssetModel) => ReactNode
}) => {
  const HeadingTag = headingTag

  return (
    <div
      className="flex flex-col gap-5"
      id={model.heading ? standardizeUrl(model.heading) : undefined}
    >
      <HeadingTag className="font-heading text-heading-large">
        {model.heading}
      </HeadingTag>

      <Layout layout={model.layoutOptions}>
        {model.path.map((path, index) => {
          if (richContentAssetModelPathModelIsImageAssetModel(path)) {
            return (
              <div className="order-2 mdu:order-none" key={index}>
                {imageAssetModel(path)}
              </div>
            )
          }
          if (richContentAssetModelPathModelIsRichTextAssetModel(path)) {
            if (!path.genericRichTextField.html) return null

            const maybeButton = model.path.find(
              richContentAssetModelPathModelIsLinkButtonAssetModel
            )

            return (
              <div className="flex flex-col gap-[18px]" key={index}>
                {richTextAssetModel(path)}

                {maybeButton ? (
                  <div>{linkButtonAssetModel(maybeButton)}</div>
                ) : null}
              </div>
            )
          }

          if (richContentAssetModelPathModelIsLinkButtonAssetModel(path)) {
            // buttons are rendered alongside rich text asset above
            return null
          }

          return null
        })}
      </Layout>
    </div>
  )
}

const Layout = ({
  layout,
  children,
}: PropsWithChildren<{ layout: RichContentAssetModel['layoutOptions'] }>) => {
  return (
    <div
      className={cn({
        'flex flex-col mdu:grid mdu:grid-cols-2 gap-8': layout === '2Column',
        'flex flex-col gap-8': layout === 'fullWidth',
      })}
    >
      {children}
    </div>
  )
}
