const removeDuplications = (pathArray: any[]) => {
  // Remove any tag duplications by creating a Set
  const uniquePathSet = new Set(pathArray)
  // Convert back to Array
  const uniquePathArray = [...uniquePathSet]
  return uniquePathArray
}

// The parent tag position in tag path returned from AEM
const parentPosition = 1

// The start of children tag position in tag path returned from AEM
const childPosition = parentPosition + 1

// The tag path seperator symbol in AEM
const pathSeparator = '/'

export function getParentTagNames(paths: Array<string>): any[] {
  // any is temporary, needs to be looked at
  let parentTagArray = []

  parentTagArray = paths.map((item: string) => {
    const pathItems = item.split(pathSeparator)
    return pathItems.length > parentPosition && pathItems[parentPosition]
  })

  const uniqueParentTagArray = removeDuplications(parentTagArray)
  return uniqueParentTagArray
}

export function getChildrenTagNames(paths: Array<string>) {
  const childrenTagArray: any[] = []

  paths.map((item: string) => {
    const pathItems = item.split(pathSeparator)
    for (let i = childPosition; i < pathItems.length; i++) {
      childrenTagArray.push(pathItems[i])
    }
  })

  const uniqueChildTagArray = removeDuplications(childrenTagArray)
  return uniqueChildTagArray
}
