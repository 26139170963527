import type { AEMStepsProps } from './aem-steps.types'
import { standardizeUrl } from '@abcam-web/shared/utilities/seo'
import { Steps } from '@lego/ui/steps'
import classnames from 'classnames'
import { Detector, AEMHTMLContent } from '@abcam-web/shared/ui/content'
import { headingTestId, detectorTestId } from './constants'

/**
 * Component that is the UI equivalent for a Steps model in AEM
 */
export function AEMSteps({
  heading,
  steps,
  options,
  detectorChange,
}: AEMStepsProps) {
  return (
    <div
      className={classnames(
        'flex flex-col gap-10',
        options?.containerClassName ?? ''
      )}
    >
      {heading && detectorChange && (
        <Detector
          testId={detectorTestId}
          detectionValue={heading}
          onChange={(event) => {
            if (event.visible) detectorChange(event.value)
          }}
        >
          <h2
            data-testid={headingTestId}
            className={classnames(
              'text-3xl font-heading',
              options?.headingClassName ?? ''
            )}
            id={heading ? standardizeUrl(heading) : undefined}
          >
            {heading}
          </h2>
        </Detector>
      )}
      <Steps
        steps={steps.map((step: string, i: number) => {
          return {
            stepNumber: i + 1,
            content: (
              <AEMHTMLContent
                key={'aem-steps' + i}
                testId={'aem-steps' + i}
                render={step}
                classNames={classnames(
                  'text-lg',
                  options?.contentClassName ?? ''
                )}
              />
            ),
          }
        })}
      />
    </div>
  )
}
