const testimonialTextTestId = 'testimonial-text'

const dummy = {
  testimonialPersonName: 'Jane Doe',
  testimonialOrganizationName: 'Test organization',
  dummyProductId: '123456',
  dummyTestimonialTitle: 'Testimonial',
  dummyTestimonialBody:
    "“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s”",
}

const dummyTestimonials = [
  {
    building: 'Test building',
    testimonial: dummy.dummyTestimonialBody,
    organisation: dummy.testimonialOrganizationName,
    person: {
      givenName: 'Jane',
      familyName: 'Doe',
    },
    abId: dummy.dummyProductId,
  },
  {
    building: 'Test building2',
    testimonial: dummy.dummyTestimonialBody,
    organisation: dummy.testimonialOrganizationName,
    person: {
      givenName: 'Jane2',
      familyName: 'Doe2',
    },
    abId: dummy.dummyProductId,
  },
]

export { testimonialTextTestId, dummy, dummyTestimonials }
