export enum ImageAssetModelHeaderOptions {
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
}

export type MultiFormatString = {
  plaintext?: string | null
  html?: string | null
  markdown?: string | null
  json?: string | null
}

export type ImageRef = {
  _path: string
  /**
   * @deprecated Use {@link ImageRef._path} instead.
   */
  _publishUrl: string
  type: string
  width: number
  height: number
  mimeType: string
}

export type DownloadUrlModel = {
  downloadUrl: string
  fileName: string
  assetType: string
  authenticationRequired: boolean
}

export type DownloadAssetPath = {
  fileName: string
  assetType: string
  downloadAssetPath: ImageRef
}

export type ExpandedImageAssetModel = {
  __typename: 'ImageAssetModel'
} & ImageAssetModel

export type ImageAssetModel = {
  image: ImageRef
  imageCaption: MultiFormatString
  header?: string
  headerElement?: ImageAssetModelHeaderOptions
}

export type ExpandedRichTextAssetModel = {
  __typename: 'RichText_asset_Model'
} & RichTextAssetModel

export type RichTextAssetModel = {
  header: string
  genericRichTextField: MultiFormatString
}

export type PersonModel = {
  familyName?: string
  givenName?: string
  postNominalLetters?: string
  title?: string
  photoImageAssetPath?: ImageRef
  thisPersonLinkedInAccountUrl?: string
  thisPersonsBio?: Omit<MultiFormatString, 'plaintext' | 'markdown' | 'json'>
}

export type LinkButtonAssetModel = {
  __typename?: 'LinkButtonAssetModel'
  buttonLabel: string
  buttonLink: string
  buttonIcon?: string
  buttonDescription?: string | null
}

export type EmailButtonAssetModel = {
  __typename: 'EmailButtonAssetModel'
  buttonLabel: string
  buttonEMail: string
  buttonDescription?: string | null
}

export interface RichContentAssetModel {
  __typename: 'RichContentAssetModel'
  layoutOptions: '2Column' | 'fullWidth'
  heading: string | null
  path: (
    | (ImageAssetModel & { __typename: 'ImageAssetModel' })
    | (RichTextAssetModel & { __typename: 'RichText_asset_Model' })
    | LinkButtonAssetModel
  )[]
}

export type StepsAssetModel = {
  __typename: 'StepsAssetModel'
  heading: string
  steps: Pick<MultiFormatString, 'html'>[]
}

export type SectionBlockModel = {
  sectionTitle: string
  sectionDescription: Pick<MultiFormatString, 'html'>
  content: RichContentAssetModel[]
}

/** Some legal detail page types are here because customer accounts team needs it */

export type LegalRequestResponse = {
  legalPageList: {
    items: LegalContent[]
  }
}

export type LegalContent = {
  dateLastModified: string
  title: string
  urlSlug: string
  introduction: Pick<MultiFormatString, 'html'>
  summary: Pick<MultiFormatString, 'plaintext'>
  headerImage?: ImageAssetModel | null
  content: Pick<MultiFormatString, 'html'>
  metaTitle: string
  metaDescription: Pick<MultiFormatString, 'plaintext'>
  metaKeywords: Pick<MultiFormatString, 'plaintext'>
}
