import { useMemo } from 'react'
import {
  AEMHTMLContent,
  ImageAssetModelHeaderOptions,
  AEMImage,
  Detector,
} from '@abcam-web/shared/ui/content'
import { headerTestId, imageCaptionTestId } from './constants'
import { standardizeUrl } from '@abcam-web/shared/utilities/seo'
import type { ImageAssetProps } from './image-asset.types'
import classNames from 'classnames'

/**
 * Component that accepts an AEM image asset fragment and renders it
 */
export function ImageAsset({
  imageUrl,
  imageCaption,
  header,
  withDetector,
}: ImageAssetProps) {
  const componentHeader = useMemo(() => {
    if (header) {
      const { title, element, className } = header

      if (title) {
        if (element?.toUpperCase() === ImageAssetModelHeaderOptions.H2) {
          return (
            <h2
              data-testid={headerTestId}
              id={standardizeUrl(title)}
              className={classNames('mb-6 font-bold', className ?? '')}
            >
              {title}
            </h2>
          )
        } else if (element?.toUpperCase() === ImageAssetModelHeaderOptions.H3) {
          return (
            <h3
              data-testid={headerTestId}
              id={standardizeUrl(title)}
              className={classNames('mb-6 font-bold', className ?? '')}
            >
              {title}
            </h3>
          )
        }

        return (
          <h4
            data-testid={headerTestId}
            id={standardizeUrl(title)}
            className={classNames('mb-6 font-bold', className ?? '')}
          >
            {title}
          </h4>
        )
      }
    }
    return null
    // it requires more work to do a deep comparison for header here, which is unnecessary for our purposes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [header?.title, header?.element, header?.className])

  return (
    <div>
      {withDetector && header?.title && (
        <Detector
          detectionValue={header.title}
          onChange={(event) => {
            if (event.visible) {
              withDetector(event.value)
            }
          }}
        >
          {componentHeader}
        </Detector>
      )}
      {!withDetector && header && componentHeader}
      <AEMImage alt={imageCaption || ''} src={imageUrl} />
      {imageCaption && (
        <AEMHTMLContent
          classNames="mt-4 text-sm text-grey50"
          render={imageCaption}
          testId={imageCaptionTestId}
        />
      )}
    </div>
  )
}
