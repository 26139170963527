import {
  ClickId,
  LocationID,
  getAnchorLinksParameters,
  getPageSubType,
  getPageType,
  useGtm,
} from '@abcam-web/shared/data-access/tracking'
import { standardizeUrl } from '@abcam-web/shared/utilities/seo'
import classnames from 'classnames'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import type {
  IsLocationSetStates,
  SideNavChildNodeProps,
  SideNavNode,
  SideNavProps,
} from './side-nav.types'
import { useCountry } from '@abcam-web/shared/utilities/country'

export function SideNavChildNode({
  children,
  node,
  activeNode,
  index,
  setActiveNode,
  setMobileMenuOpen,
}: SideNavChildNodeProps): JSX.Element {
  const { value, parent } = node
  const gtm = useGtm()
  const { asPath, pathname } = useRouter()
  const path = asPath.split('#')[0]

  const { countryName } = useCountry()

  return (
    <>
      <li className="hover:rounded-3xl hover:bg-grey-5">
        <a href={`#${standardizeUrl(value)}`} className="hover:no-underline">
          <p
            className={classnames(
              'list-none cursor-pointer mb-2 leading-6',
              parent
                ? 'py-3 px-8'
                : 'text-base font-semibold tracking-[0.5px] py-3 px-5'
            )}
            onClick={(event) => {
              event.stopPropagation()
              setActiveNode(value)
              setMobileMenuOpen(false)
              window.dispatchEvent(new HashChangeEvent('hashchange'))
              gtm.track(
                getAnchorLinksParameters({
                  click_id: ClickId.ANCHOR_LINKS,
                  location_id: LocationID.PAGE,
                  page_path: path,
                  page_type: getPageType(pathname),
                  page_subtype: getPageSubType(pathname),
                  item_cta: value,
                  country: countryName,
                  index: index,
                  toggle: null,
                  widget: null,
                  country_selection: null,
                  purchasing_organisations: null,
                  distributor_name: null,
                  drawer_type: null,
                  destination_path: null,
                })
              )
            }}
          >
            <span>{value}</span>
          </p>
        </a>
      </li>
      {children && <ul className="px-5 py-3">{children}</ul>}
    </>
  )
}

export function SideNavigation({
  sideNavNodes,
  active,
  headingText,
}: SideNavProps) {
  const [activeNode, setActiveNode] = useState(() => active)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [mobileVisible, setMobileVisible] = useState(false)
  const [isLocationSet, setIsLocationSet] = useState<IsLocationSetStates>(null)
  let timeoutVariable: NodeJS.Timeout

  const topSideNavMarker = useRef<HTMLDivElement | null>(null) // Top of sidenav container
  const bottomSideNavMarker = useRef<HTMLDivElement | null>(null) // Bottom of sidenav container
  const navRef = useRef<HTMLElement | null>(null) // Elements in sidenav container

  const [hideSideNav, setHideSideNav] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHideSideNav(
        !window.IntersectionObserver || !window.IntersectionObserverEntry
      )
    }
  }, [])

  useEffect(() => {
    setActiveNode(active)
  }, [active])

  function handleScroll() {
    // Note that we are not throttling the scroll events, but that is necessary for a fluid motion
    // when transitioning to the fixed states

    const navElement = navRef.current?.getBoundingClientRect()
    const topMarker = topSideNavMarker.current?.getBoundingClientRect()
    const bottomMarker = bottomSideNavMarker.current?.getBoundingClientRect()

    if (!bottomMarker || !navElement || !topMarker) {
      // Fix (or rather align) side navigation to top of parent container
      setIsLocationSet('top')
      // Fix (or rather align) side navigation to bottom of parent container
    } else if (bottomMarker?.y - navElement?.height <= 10) {
      setIsLocationSet('bottom')
    } else if (topMarker?.y >= -10) {
      // Allow it to be fixed to the viewport
      setIsLocationSet(null)
    } else {
      // Fix (or rather align) side navigation to top of parent container
      setIsLocationSet('top')
    }

    // Debounce so that we are not calling state updates all the time for mobile check
    clearTimeout(timeoutVariable)
    timeoutVariable = setTimeout(() => {
      // This is not depreciated, see: https://developer.mozilla.org/en-US/docs/Web/API/Window/pageYOffset
      if (window.pageYOffset < 200) {
        setMobileVisible(false)
      } else {
        setMobileVisible(true)
      }
    }, 100)
  }

  function calculateSetLocation() {
    if (isLocationSet === 'top') {
      return 'fixed top-0 mt-4'
    }

    // Note that another change is also happening at the bottom marker div
    if (isLocationSet === 'bottom') {
      return 'mt-auto'
    }

    return ''
  }

  useEffect(() => {
    handleScroll() // Only runs once on component load to correct the initial state

    window.addEventListener('scroll', handleScroll, false)

    return () => window.removeEventListener('scroll', handleScroll, false)
  }, [])

  function renderNodes(
    node: SideNavNode[],
    parentIndex?: string
  ): JSX.Element[] {
    return node.map((node, index) => {
      const { value } = node
      const treeIndex = parentIndex
        ? `${parentIndex}.${index.toString()}`
        : `${index.toString()}`

      if (node.children && node.children.length !== 0) {
        return (
          <SideNavChildNode
            activeNode={activeNode}
            key={value}
            node={node}
            setActiveNode={setActiveNode}
            setMobileMenuOpen={setMobileMenuOpen}
            index={treeIndex}
          >
            {renderNodes(node.children, treeIndex)}
          </SideNavChildNode>
        )
      }

      return (
        <SideNavChildNode
          activeNode={activeNode}
          key={value}
          node={node}
          setActiveNode={setActiveNode}
          setMobileMenuOpen={setMobileMenuOpen}
          index={treeIndex}
        />
      )
    })
  }

  const nodeTree = useMemo(() => {
    return renderNodes(sideNavNodes)
  }, [sideNavNodes, activeNode])

  return (
    !hideSideNav ? (
      <>
        <div className="flex flex-col h-full lgd:hidden lgu:visible">
          <div className="w-100" ref={topSideNavMarker}></div>
          <nav
            aria-labelledby="sidenav-title"
            className={classnames(
              'max-w-[280px] mx-5 flex flex-col w-full [&_p]:text-black-0',
              calculateSetLocation()
            )}
            ref={navRef}
          >
            {headingText ? (
              <h2
                className="px-5 py-3 text-grey-20 text-body-small"
                id="sidenav-title"
              >
                {headingText}
              </h2>
            ) : null}

            <ul>{nodeTree}</ul>
          </nav>
          <div
            className={classnames(
              isLocationSet === 'bottom' ? '' : 'mt-auto',
              'w-100'
            )}
            ref={bottomSideNavMarker}
          ></div>
        </div>
        {activeNode && mobileVisible && (
          <div className="top-0 z-20 lgd:visible lgu:hidden lgd:fixed lgd:w-full lgd:right-0">
            <div className="fixed w-full bg-grey-5">
              {!mobileMenuOpen && (
                <div className="flex my-6">
                  <svg
                    className="mx-6 cursor-pointer"
                    fill="none"
                    height="24"
                    onClick={() => {
                      setMobileMenuOpen((currentValue) => !currentValue)
                    }}
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M3 7a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2H3zm0 6a1 1 0 1 1 0-2h18a1 1 0 1 1 0 2H3zm-1 5a1 1 0 0 0 1 1h18a1 1 0 1 0 0-2H3a1 1 0 0 0-1 1z"
                      fill="#2A3C3C"
                      fillRule="evenodd"
                    />
                  </svg>
                  <p>{activeNode}</p>
                </div>
              )}
              {mobileMenuOpen && (
                <div className="flex my-6 [&_p]:text-black-0">
                  <svg
                    className="mx-6 cursor-pointer"
                    fill="none"
                    height="24"
                    onClick={() => {
                      setMobileMenuOpen(!mobileMenuOpen)
                    }}
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M4.293 19.707a1 1 0 0 0 1.414 0L12 13.414l6.293 6.293a1 1 0 0 0 1.414-1.414L13.414 12l6.293-6.293a1 1 0 0 0-1.414-1.414L12 10.586 5.707 4.293a1 1 0 0 0-1.414 1.414L10.586 12l-6.293 6.293a1 1 0 0 0 0 1.414z"
                      fill="#2A3C3C"
                      fillRule="evenodd"
                    />
                  </svg>
                  <ul>{nodeTree}</ul>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    ) : null
  )
}
