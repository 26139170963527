import { useMemo } from 'react'
import type { TestimonialProps } from './testimonial.type'
import { testimonialTextTestId } from './constants'
import { AEMHTMLContent } from '@abcam-web/shared/ui/content'
import { standardizeUrl } from '@abcam-web/shared/utilities/seo'

export function calculateTestimonialPerson(
  givenName?: string,
  familyName?: string
) {
  if (!givenName || !familyName) {
    return 'Customer'
  }

  return `${givenName} ${familyName}`
}

export function calculateTestimonialCaption(
  personName?: string,
  organization?: string | null,
  product?: string | null
) {
  let testimonialCaption = personName ? personName : 'Customer'

  if (organization) {
    testimonialCaption += `, ${organization}`
  }

  if (product) {
    testimonialCaption += `, ${product}`
  }

  return testimonialCaption
}

export function Testimonial({
  testimonialTitle,
  testimonials,
}: {
  testimonialTitle: string
  testimonials: TestimonialProps[] | null
}) {
  const testimonialArr = useMemo(() => {
    return testimonials?.map(
      ({ testimonial, person, organisation, abId }, index) => {
        const { givenName, familyName } = person

        // Top testimonial is always highlighted and has a different layout
        const firstTestimonial = index === 0

        const testimonialContent = (
          <div
            className={
              firstTestimonial ? 'mb-10 sm:w-full' : 'sm:w-full smu:w-1/3 mb-4'
            }
            key={testimonial.trim() + index}
          >
            <AEMHTMLContent
              testId={testimonialTextTestId}
              classNames={
                firstTestimonial
                  ? 'mb-3 font-header text-2xl'
                  : 'mb-3 font-body mr-2'
              }
              render={testimonial}
            />
            <p
              data-testid="testimonial-caption"
              className="text-sm text-grey50"
            >
              {calculateTestimonialCaption(
                calculateTestimonialPerson(givenName, familyName),
                organisation,
                abId
              )}
            </p>
          </div>
        )

        return testimonialContent
      }
    )
  }, [testimonials])

  // Note that we cannot use something like [testimonial, ...rest] = testimonials.map() here because react doesn't support it
  const firstTestimonial = testimonialArr?.slice(0, 1) // First testimonial
  const remainingTestimonials = testimonialArr?.slice(1) // Rest
  return (
    <div>
      <h2
        id={standardizeUrl(testimonialTitle)}
        className="mb-8 text-2xl font-header"
      >
        {testimonialTitle}
      </h2>
      <div className="flex flex-col">{firstTestimonial}</div>
      {remainingTestimonials && (
        <div className="flex flex-wrap">{remainingTestimonials}</div>
      )}
    </div>
  )
}
