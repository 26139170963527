import {
  ImageAssetModel,
  LinkButtonAssetModel,
  RichContentAssetModel,
  RichTextAssetModel,
} from '../types/aem.types'

type PathModel = RichContentAssetModel['path'][number]

export const richContentAssetModelPathModelIsImageAssetModel = (
  pathModel: PathModel
): pathModel is ImageAssetModel & { __typename: 'ImageAssetModel' } =>
  !!(pathModel.__typename === 'ImageAssetModel')

export const richContentAssetModelPathModelIsRichTextAssetModel = (
  pathModel: PathModel
): pathModel is RichTextAssetModel & { __typename: 'RichText_asset_Model' } =>
  !!(pathModel.__typename === 'RichText_asset_Model')

export const richContentAssetModelPathModelIsLinkButtonAssetModel = (
  pathModel: PathModel
): pathModel is LinkButtonAssetModel =>
  !!(pathModel.__typename === 'LinkButtonAssetModel')
