import { Detector, AEMHTMLContent } from '@abcam-web/shared/ui/content'
import { standardizeUrl } from '@abcam-web/shared/utilities/seo'
import { contentTestId, headerTestId } from './constants'
import type { RichTextAssetProps } from './rich-text-asset.types'
import classNames from 'classnames'

/**
 * Component that is the UI equivalent for a Rich Text (asset) model in AEM
 */
export function RichTextAsset({
  classname,
  genericRichTextField,
  setActiveSideNavNode,
  header,
}: RichTextAssetProps) {
  return (
    <div
      className={classNames(
        'font-body text-body-large',
        classname ? classname : ''
      )}
    >
      {header && (
        <Detector
          detectionValue={header}
          onChange={(event) => {
            if (event.visible) {
              setActiveSideNavNode(event.value)
            }
          }}
        >
          <h2
            data-testid={headerTestId}
            id={standardizeUrl(header)}
            className="mb-6 font-header"
          >
            {header}
          </h2>
        </Detector>
      )}
      <AEMHTMLContent testId={contentTestId} render={genericRichTextField} />
    </div>
  )
}
